<template>
  <div>
    <v-row>
      <v-col cols="12">
        <Table
          :servicePath="'message/list/' + type"
          :headers="tableHeaders"
          :title="tableTitle"
          :itemsPerPageSent="itemsPerPageSent"
          :sortByKey="sortBy"
          :sortDesc="sortDesc"
          icon="mdi-email"
          tableType="mass"
          v-on:addManager="addManagerDialog = true"
          v-on:editManager="editManager"
          v-on:activity="activity"
          :reload="reload"
          :hideFilters="true"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from "@/components/shared/Table";
export default {
  components: {
    Table,
  },
  data: function() {
    console.log(this.$route.params.type);
    return {
      reload: false,
      addManagerDialog: false,
      editManagerDialog: false,
      editableManager: false,
      activityDialog: false,
      activityUserID: false,
      sortBy: ["date"],
      sortDesc: [true],
      itemsPerPageSent: 50,
      type: this.$route.params.type,
    };
  },
  methods: {
    closePopup() {
      this.addManagerDialog = false;
      this.editManagerDialog = false;
      this.activityDialog = false;
    },
    editManager(i) {
      this.editableManager = i;
      this.editManagerDialog = true;
    },
    activity(id) {
      this.activityUserID = id;
      this.activityDialog = true;
    },
  },
  computed: {
    tableTitle() {
      return this.$vuetify.lang.t("$vuetify.navigations.sent_notifications");
    },
    tableHeaders() {
      var headers = {
        inbox: [
          {
            text: this.$vuetify.lang.t("$vuetify.tables.name"),
            value: "company.name",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.message.subject"),
            value: "title",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.message.sent_date"),
            value: "date",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.action.action"),
            value: "viewNotification",
            sortable: false,
          }
        ],
        sms: [
          {
            text: this.$vuetify.lang.t("$vuetify.tables.name"),
            value: "company.name",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.manager.status"),
            value: "status",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.company.phone"),
            value: "company.phone",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.message.sent_date"),
            value: "date",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.action.action"),
            value: "viewNotification",
            sortable: false,
          }
        ],
        email: [
          {
            text: this.$vuetify.lang.t("$vuetify.tables.company"),
            value: "company.name",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.manager.status"),
            value: "status",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.company.email"),
            value: "company.email",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.message.subject"),
            value: "custom_title",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.message.sent_date"),
            value: "date",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.action.action"),
            value: "viewNotification",
            sortable: false,
          }
        ],
      };
      return headers[this.type];
    },
  },
  watch: {
    $route(to) {
      this.type = to.params.type;
    },
  },
};
</script>
